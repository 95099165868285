import React from 'react';

interface WellProps {
	children: React.ReactNode;
	show: boolean;
}

const Well = ({children, show}: WellProps) => {
	return show ? <div className={'well'}>{children}</div> : null;
};

export default Well;
