import React from 'react';

interface ButtonProps {
  name: string;
  onClick: () => void;
  selected: boolean;
  tabIndex?: number;
}


const Button = ({name, onClick, selected, tabIndex = 0}: ButtonProps) => (
  <button
    className={`row-item row-btn ${selected ? 'row-btn-selected' : ''}`}
    onClick={onClick}
    tabIndex={tabIndex}
  >
    {name}
  </button>
);

export default Button;
