import React, {useState} from 'react';
import Button from './Button';
import Well from './Well';
import WellLink from "./WellLink";


const Page = () => {

  const [showSocial, setShowSocial] = useState(false);
  const [showPortfolio, setShowPortfolio] = useState(false);

  const toggleSocial = () => {
    if (showSocial) {
      setShowSocial(false);
    } else {
      setShowSocial(true);
      setShowPortfolio(false);
    }
  };

  const togglePortfolio = () => {
    if (showPortfolio) {
      setShowPortfolio(false);
    } else {
      setShowPortfolio(true);
      setShowSocial(false);
    }
  };

  return (
    <div className={'app'}>
      <header className={'app-header'}>
        <h1>David Swartout</h1>
        <h2>Software Engineer</h2>
        <div className={'button-row'}>
          <Button name={'Social'} onClick={toggleSocial} selected={showSocial} tabIndex={1} />
          <span className={'row-item'}>&bull;</span>
          <Button name={'Portfolio'} onClick={togglePortfolio} selected={showPortfolio} tabIndex={2} />
          <span className={'row-item'}>&bull;</span>
          <a className={'row-btn'} href='Swartout-Resume.pdf' tabIndex={3}>Résumé</a>
        </div>
      </header>
      <Well show={showSocial}>
        <WellLink href={'https://www.github.com/xanatosinc'} tabIndex={1}>GitHub</WellLink>
        <span className={'well-item-dark'}>&bull;</span>
        <WellLink href='https://www.linkedin.com/in/davidswartout' tabIndex={1}>LinkedIn</WellLink>
      </Well>
      <Well show={showPortfolio}>
        <WellLink href='https://www.pacoiwi.com' tabIndex={2}>
          <img alt='Thumbnail representation of www.pacoiwi.com homepage' className={'thumb'}
               src='/thumbs/pci-cap.png' />
        </WellLink>
        <WellLink href='https://alyse.larkin.science' tabIndex={2}>
          <img alt='Thumbnail representation of alyse.larkin.science' className={'thumb'}
               src='/thumbs/science-thumb.jpg' />
        </WellLink>
      </Well>
    </div>
  )
};

export default Page;
