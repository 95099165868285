import React from 'react';
import Page from './components/Page';
import './App.css';

function App() {
  return (
    <Page />
  );
}

export default App;
