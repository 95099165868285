import React from 'react';

interface WellLinkProps {
  children: React.ReactNode;
  href: string;
  tabIndex?: number;
}

const WellLink = ({children, href, tabIndex = 0}: WellLinkProps) => (
  <a className={'well-link-dark'} href={href} tabIndex={tabIndex}>{children}</a>
);

export default WellLink;